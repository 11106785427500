<template>
<div>
    <v-card outlined>

        <v-data-table :items-per-page="-1" dense :headers="headers" :items="getInputs" class="elevation-0" :disable-pagination="false" hide-default-footer>

            <template v-slot:item.name="{ item }">
                <div style="width:550px"> {{item.input_group.code + "-" + item.full_code+' - '+ item.name }} </div>
            </template>

            <template v-slot:item.unity="{ item }">
                 {{item.unity }}
            </template>

            <template v-slot:item.value="{ item }">
                {{ formatMoneyPrecision3(item.value) }}
            </template>
            <template v-slot:item.total="{ item }">
                {{ formatMoneyPrecision3(item.pivot.quantity * item.value) }}
            </template>

            <template v-slot:item.pivot.quantity="{ item }">
                <v-edit-dialog :return-value.sync="item.pivot.quantity" large cancel-text="CANCELAR" save-text="SALVAR" @save="saveQuantity(item)">
                    <div class="editable">
                        <div style="width:100px;float:'right'">{{ formatMoneyPrecision3(item.pivot.quantity) }}</div>
                    </div>
                    <template v-slot:input>
                        <vuetify-money v-model="item.pivot.quantity" :clearable="true" valueWhenIsEmpty="0" :options="{
                                                        locale: 'pt-BR',
                                                        length: 11,
                                                        precision: 3,
                                                    }" />
                    </template>
                </v-edit-dialog>

            </template>

            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Insumos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>

                    <v-btn class="mx-2" :disabled="disabled" small fab dark @click="showDialogFindInput" color="primary">
                        <v-icon dark> mdi-plus </v-icon>
                    </v-btn>

                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <BarTitle title="Excluir" @close="dialogDelete = false"></BarTitle>
                            <v-card-text class="text-center text-h6">Tem certeza de que deseja excluir este item?</v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="mr-2" min-width="150" color="secondary" large text @click="closeDelete">fechar</v-btn>
                                <v-btn color="error" min-width="150" large @click="deleteItemConfirm">SIM</v-btn>

                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon :disabled="disabled" color="error" @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data> Nenhum registro foi encontrado. </template>
            <template v-slot:foot>
                <tr>
                    <td class="tax back_tax" colspan='3'></td>
                    <td class="tax text-right back_tax">ENCARGOS:</td>
                    <td class="tax text-right back_tax">{{ formatMoneyPrecision3(calcTax) }}</td>
                    <td class="tax back_tax"></td>
                </tr>
                <tr>
                    <td class="tax back_total" colspan='3'></td>
                    <td class="tax text-right back_total">TOTAL:</td>
                    <td class="tax text-right back_total">{{ formatMoneyPrecision3(calcTotal)}}</td>
                    <td class="tax back_total"></td>
                </tr>
            </template>

        </v-data-table>
    </v-card>

    <!-- dialog de pesquisa de insumos -->
    <v-dialog v-model="dialog" max-width="900px">
        <v-card>

            <BarTitle title="Localizar Insumos" @close="dialog=false"></BarTitle>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="">
                            <v-text-field outlined v-model="searchInput" @keyup.enter="findInputs()" @click:append="findInputs()" append-icon="mdi-magnify" label="Localizar">
                            </v-text-field>
                        </v-col>
                        <!-- <v-col>
                                <v-btn outlined color="purple" @click="findInputs()"><v-icon>mdi-magnify</v-icon>
                                    Buscar</v-btn>
                            </v-col> -->
                    </v-row>
                    <v-card outlined>
                        <v-data-table height='355' dense :loading="loadindTableFindInputs" :headers="headersFindInputs" :items="findInputsData" :items-per-page="10" class="elevation-0" @click:row="setInputSelected" :footer-props="{
                                itemsPerPageAllText: 'todos',
                                itemsPerPageText: 'linhas por pagina',
                            }">
                            <template v-slot:item.full_code="{ item }">
                                {{ item.input_group.code + "-" + item.full_code }}
                            </template>

                            <template v-slot:item.value="{ item }">
                                {{ formatMoney(item.value) }}
                            </template>

                            <template v-slot:no-data>
                                Nenhum registro foi encontrado.
                            </template>

                        </v-data-table>
                    </v-card>
                    <v-expand-transition>
                        <v-card class="mt-6" outlined v-show="showInputSelected">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="7">
                                        <v-text-field hide-details type="text" outlined label="Insumo" :readonly="true" v-model="findInputSelected.name"></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field hide-details type="number" class="ml-2" outlined label="Quantidade" :error-messages="errorQuantity" v-model="findInputSelected.pivot.quantity"></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn color="primary" x-large class="ml-2" @click="applyInputSelected()">Aplicar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-expand-transition>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    mapGetters,
    mapMutations,
    mapState
} from "vuex";
import service from "../service";
import BarTitle from "@/pages/Components/BarTitle"
export default {

    props: {
        disabled: Boolean
    },
    components: {
        BarTitle
    },
    data: () => ({
        errorQuantity: [],
        showInputSelected: false,
        findInputSelected: {
            pivot: {
                quantity: ""
            }
        },
        loadindTableFindInputs: false,
        findInputsData: [],
        searchInput: "",
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: "DESCRIÇÃO",
                value: "name",
                sortable: false
            },
            {
                text: "UNID",
                align: 'center',
                value: "unity",
                sortable: false
            },
            {
                text: "COEF.",
                value: "pivot.quantity",
                align: 'right',
                sortable: false
            },
            {
                text: " P UNIT.(R$)",
                align: 'right',
                value: "value",
                sortable: false
            },
            {
                text: "SUB-TOTAL(R$)",
                align: 'right',
                value: "total",
                sortable: false
            },

            {
                text: "",
                value: "actions",
                align: 'right',
                sortable: false
            },
        ],
        headersFindInputs: [{
                text: "CÓDIGO",
                align: "start",
                sortable: false,
                value: "full_code",
            },
            {
                text: "NOME",
                value: "name",
            },
            {
                text: "UNIDADE",
                value: "unity"
            },
            {
                text: "VALOR",
                value: "value"
            },
            {
                text: "",
                align: 'end',
                value: "actions",
                sortable: false
            },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            full_code: "",
            name: "",
            unity: "",
            value: "",
        },
        defaultItem: {
            full_code: "",
            name: "",
            unity: "",
            value: "",
        },
    }),

    computed: {
        ...mapGetters("Composition", ["getInputs"]),
        ...mapState("Composition", {
            inputs: (state) => state.inputs,
        }),
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
        calcTax() {
            const totaltax = this.getInputs.reduce((acc, input) => {
                return acc + parseFloat(input.pivot.quantity * (input.value * (input.tax / 100)))
            }, 0)
            return totaltax
        },
        calcTotal() {
            const total = this.getInputs.reduce((acc, input) => {
                return acc + parseFloat(input.pivot.quantity * input.value_with_tax)
            }, 0)
            return total
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    methods: {
        ...mapMutations("Composition", ["deleteInput", "addInput", 'storeQuantity']),

        saveQuantity(item) {
            this.storeQuantity(item)
        },

        deleteItem(item) {
            this.editedIndex = this.inputs.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            //this.desserts.splice(this.editedIndex, 1)
            this.deleteInput(this.editedIndex);
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem);
            } else {
                this.desserts.push(this.editedItem);
            }
            this.close();
        },

        findInputs() {
            this.loadindTableFindInputs = true;
            service.getFindInputs(this.searchInput).then((response) => {
                this.loadindTableFindInputs = false;
                this.findInputsData = response.data;
            });
        },
        setInputSelected(input) {
            input = Object.assign({}, input);
            this.showInputSelected = true;
            input.pivot = {
                quantity: ""
            };
            this.findInputSelected = input;
        },
        //&&
        applyInputSelected() {

            if (this.findInputSelected.pivot.quantity <= 0) {
                this.errorQuantity = ["Obrigatório"];
                this.$toast.error("Quantidade é um campo obrigatório");
            } else {
                this.errorQuantity = [];
                this.addInput(this.findInputSelected);
                this.close();
                this.showInputSelected = false;
            }
        },
        showDialogFindInput() {
            this.searchInput = ''
            this.showInputSelected = false;
            this.errorQuantity = [];
            this.dialog = true;
            this.loadindTableFindInputs = true;
            service.getFindInputs("").then((response) => {
                this.loadindTableFindInputs = false;
                this.findInputsData = response.data;
            });
        },
        formatMoney(number) {
            number = this.parseNumber(number)
            return number.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },
        formatMoneyPrecision3(number) {
            number = this.parseNumber(number)
            return number.toLocaleString('pt-br', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
            })
        },

        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },
    },
};
</script>

<style scoped>
.editable {
    color: #1976d2;
    font-weight: bold
}

.tax {
    box-sizing: border-box;
    padding-right: 16px;
    padding-left: 16px;
    height: 30px;
    font-weight: bold;
}

.back_tax {
    background: lightgray;
}

.back_total {
    background: #1976d2;
    color: white;
}
</style>
