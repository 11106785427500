import axios from '@/axios'
const route = 'register/composition'
export default {

    getAll(itemsPerPage,page,compositionGroupId,search) {
        return axios.get(route+'?page='+page+'&items_per_page='+itemsPerPage+'&composition_group_id='+compositionGroupId+'&search='+search)
    },

    store(composition) {
        return axios.post(route, composition)
    },

    update(composition) {
        return axios.put(route + "/" + composition.id, composition)
    },

    destroy(composition) {
        return axios.delete(route + "/" + composition.id)
    },

    getFindInputs(nameInput) {
        return axios.post(route + "/get_find_inputs" , {search:nameInput})
    },

    getFindCompostions(nameComposition){
        return axios.post(route + "/get_find_compositions", {search: nameComposition})
    },

    getGroupComposition(){
        return axios.get(route + '/group_composition/get_all')
    }

}
