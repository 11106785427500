var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"items-per-page":-1,"dense":"","headers":_vm.headers,"items":_vm.getInputs,"disable-pagination":false,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"550px"}},[_vm._v(" "+_vm._s(item.input_group.code + "-" + item.full_code+' - '+ item.name)+" ")])]}},{key:"item.unity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unity)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoneyPrecision3(item.value))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoneyPrecision3(item.pivot.quantity * item.value))+" ")]}},{key:"item.pivot.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.pivot.quantity,"large":"","cancel-text":"CANCELAR","save-text":"SALVAR"},on:{"update:returnValue":function($event){return _vm.$set(item.pivot, "quantity", $event)},"update:return-value":function($event){return _vm.$set(item.pivot, "quantity", $event)},"save":function($event){return _vm.saveQuantity(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('vuetify-money',{attrs:{"clearable":true,"valueWhenIsEmpty":"0","options":{
                                                        locale: 'pt-BR',
                                                        length: 11,
                                                        precision: 3,
                                                    }},model:{value:(item.pivot.quantity),callback:function ($$v) {_vm.$set(item.pivot, "quantity", $$v)},expression:"item.pivot.quantity"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"editable"},[_c('div',{staticStyle:{"width":"100px","float":"'right'"}},[_vm._v(_vm._s(_vm.formatMoneyPrecision3(item.pivot.quantity)))])])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Insumos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.disabled,"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.showDialogFindInput}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('BarTitle',{attrs:{"title":"Excluir"},on:{"close":function($event){_vm.dialogDelete = false}}}),_c('v-card-text',{staticClass:"text-center text-h6"},[_vm._v("Tem certeza de que deseja excluir este item?")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"min-width":"150","color":"secondary","large":"","text":""},on:{"click":_vm.closeDelete}},[_vm._v("fechar")]),_c('v-btn',{attrs:{"color":"error","min-width":"150","large":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("SIM")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                                                    var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":_vm.disabled,"color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" Nenhum registro foi encontrado. ")]},proxy:true},{key:"foot",fn:function(){return [_c('tr',[_c('td',{staticClass:"tax back_tax",attrs:{"colspan":"3"}}),_c('td',{staticClass:"tax text-right back_tax"},[_vm._v("ENCARGOS:")]),_c('td',{staticClass:"tax text-right back_tax"},[_vm._v(_vm._s(_vm.formatMoneyPrecision3(_vm.calcTax)))]),_c('td',{staticClass:"tax back_tax"})]),_c('tr',[_c('td',{staticClass:"tax back_total",attrs:{"colspan":"3"}}),_c('td',{staticClass:"tax text-right back_total"},[_vm._v("TOTAL:")]),_c('td',{staticClass:"tax text-right back_total"},[_vm._v(_vm._s(_vm.formatMoneyPrecision3(_vm.calcTotal)))]),_c('td',{staticClass:"tax back_total"})])]},proxy:true}])})],1),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('BarTitle',{attrs:{"title":"Localizar Insumos"},on:{"close":function($event){_vm.dialog=false}}}),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":""}},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Localizar"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.findInputs()},"click:append":function($event){return _vm.findInputs()}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"height":"355","dense":"","loading":_vm.loadindTableFindInputs,"headers":_vm.headersFindInputs,"items":_vm.findInputsData,"items-per-page":10,"footer-props":{
                                itemsPerPageAllText: 'todos',
                                itemsPerPageText: 'linhas por pagina',
                            }},on:{"click:row":_vm.setInputSelected},scopedSlots:_vm._u([{key:"item.full_code",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.input_group.code + "-" + item.full_code)+" ")]}},{key:"item.value",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.value))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" Nenhum registro foi encontrado. ")]},proxy:true}])})],1),_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInputSelected),expression:"showInputSelected"}],staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"hide-details":"","type":"text","outlined":"","label":"Insumo","readonly":true},model:{value:(_vm.findInputSelected.name),callback:function ($$v) {_vm.$set(_vm.findInputSelected, "name", $$v)},expression:"findInputSelected.name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"ml-2",attrs:{"hide-details":"","type":"number","outlined":"","label":"Quantidade","error-messages":_vm.errorQuantity},model:{value:(_vm.findInputSelected.pivot.quantity),callback:function ($$v) {_vm.$set(_vm.findInputSelected.pivot, "quantity", $$v)},expression:"findInputSelected.pivot.quantity"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","x-large":""},on:{"click":function($event){return _vm.applyInputSelected()}}},[_vm._v("Aplicar")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }