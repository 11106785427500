<template>
  <v-container>
    <v-data-table :loading="loading_data_table" :headers="headers" :items="desserts" :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options"
      sort-by="corporate_name" class="elevation-1" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
      <!-- formatacao de codigo completo  -->
      <template v-slot:item.code="{ item }">
        <div style="white-space:nowrap" >{{ item.composition_group.code + "-" + item.code }}</div>
      </template>

      <template v-slot:item.total_with_tax="{ item }">
        {{ formatMoney(item.total_with_tax) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Composição</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Localizar" @click:append="searchItems()"
            @change="searchItems()" single-line hide-details class="mr-8"></v-text-field>
          <v-btn rounded color="primary" @click="newItem()" dark class="mb-2"  ><v-icon
              small>mdi-plus</v-icon>Nova Composição</v-btn>
          <v-dialog v-model="dialog" fullscreen persistent no-click-animation>
            <v-card>
                <BarTitle :title="formTitle" @close="dialog=false"></BarTitle>

   <v-card-actions class="mb-2">
                <v-spacer></v-spacer>
                <!-- <v-btn text  x-large class=" mr-4" min-width="150" @click="dialog = false">Fechar</v-btn> -->
                <v-btn color="primary" x-large class=""  min-width="150" :loading="btnLoadingSave" :disabled="btnLoadingSave"
                  @click="save"> {{ labelBtnSave }}</v-btn>
              </v-card-actions>
              <v-divider class="mb-4"></v-divider>
              <v-card-text>
                  <v-form class="px-3" @submit.prevent="submit()">
                    <v-row>
                      <v-col cols="2">
                        <v-text-field outlined :disabled="disable_inputs" :prefix="codeGroup()" hide-details  v-model="editedItem.code"
                          label="Codigo"></v-text-field></v-col>
                      <v-col><v-text-field outlined :disabled="disable_inputs" hide-details v-model="editedItem.name"
                          label="Nome"></v-text-field></v-col>
                      <!-- <v-col>
                        <v-select :disabled="disable_inputs" v-model="editedItem.composition_group_id" dense
                          :items="items_group_composition" :item-text="nameWithId" item-value="id"
                          label="Grupo de composição"></v-select>
                      </v-col> -->

                      <v-col cols="2">
                        <v-select outlined :disabled="disable_inputs" v-model="editedItem.unity" hide-details :items="items_unity"
                          label="Unidade"></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <InputTable :disabled="disable_inputs" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <CompositionTable :disabled="disable_inputs" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <vuetify-money outlined :disabled="disable_inputs" label="Total"  :readonly="true"
                          v-model="editedItem.total" valueWhenIsEmpty="0" :options="{
                            locale: 'pt-BR',
                            prefix: 'R$',
                            length: 11,
                            precision: 5,
                          }" />
                      </v-col>

                      <v-col>
                        <vuetify-money outlined :disabled="disable_inputs" label="Encargos"  v-model="editedItem.tax" :readonly="true"
                          valueWhenIsEmpty="" :options="{
                            locale: 'pt-BR',
                            prefix:'R$',
                            length: 11,
                            precision: 5,
                          }" />
                      </v-col>

                      <v-col>
                        <vuetify-money  outlined :disabled="disable_inputs" label="Total com encargos"  :readonly="true"
                          v-model="editedItem.total_with_tax" valueWhenIsEmpty="0" :options="{
                            locale: 'pt-BR',
                            prefix: 'R$',
                            length: 11,
                            precision: 5,
                          }" />
                      </v-col>
                    </v-row>
                  </v-form>

              </v-card-text>


            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="550px">
            <v-card>
              <v-card-title class="text-h5">Tem certeza de que deseja excluir este item ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" @click="closeDelete">Cancelar</v-btn>
                <v-btn class="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="blue" class="mr-1" @click="copyItem(item)"  v-bind="attrs"   v-on="on">
          <v-icon>   mdi-content-copy  </v-icon>
        </v-btn>
        </template>
      <span>Copiar</span>
    </v-tooltip>
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="primary" class="mr-1" @click="editItem(item)" v-bind="attrs" v-on="on">
              <v-icon> mdi-pencil </v-icon>
          </v-btn>    
      </template>
          <span>Editar</span>
        </v-tooltip>
      
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="mr-1" @click="showItem(item)" v-bind="attrs" v-on="on">
            <v-icon> mdi-eye </v-icon>
          </v-btn>
        </template>
          <span>Visualizar</span>
        </v-tooltip>
       
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="error" class="mr-1" @click="deleteItem(item)" v-bind="attrs" v-on="on">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </template>
          <span>Deletar</span>
        </v-tooltip>
       
        
      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>
  </v-container>
</template>


<script>
import { mapMutations, mapGetters } from "vuex";
import InputTable from "./_components/InputTable";
import CompositionTable from "./_components/CompositionTable";
import service from "./service";
import BarTitle from "@/pages/Components/BarTitle"

export default {
  components: {
    InputTable,
    CompositionTable,
    BarTitle
  },
  data: () => ({
    //tax: "",
    copyCompositionActive : false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    search: "",
    btnLoadingSave: false,
    loading_data_table: false,
    disable_inputs: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "CODIGO",
        width: true,
        align: "start",
        sortable: false,
        value: "code",
      },
      { text: "NOME", value: "name" },
      { text: "UNIDADE", value: "unity" },
      { text: "PREÇO UND.", value: "total_with_tax",align:'right' },
      { text: "", align: "end", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      composition_group_id: "",
      code: "",
      name: "",
      unity: "",
      tax: "",
      total: "",
    },
    defaultItem: {
      id: "",
      composition_group_id: "",
      code: "",
      name: "",
      unity: "",
      tax: "",
      total: "",
    },
    items_group_composition: [],
    items_unity: [
      "m",
      "m²",
      "m³",
      "g",
      "kg",
      "ton",
      "l",
      "h",
      "dia",
      "sem",
      "mês",
      "unid",
      "fl",
      "cento",
      "vb",
      "bd",
      "caixa",
      "pç",
      "jg",
      "rl",
      "vg",
      "gl",
      "lt",
      "mil",
      "pct",
      "sc",
    ],
  }),

  computed: {
    ...mapGetters("Composition", ["getSumValueInputs", "getSumTax","getSumTotal"]),
    ...mapGetters("Composition", ["getInputs", "getCompositions"]),
    ...mapGetters("CompositionGroup", ["getCompositionGroupId"]),

    labelBtnSave(){
       return this.copyCompositionActive?'Salvar Cópia':'SAlvar' 
    } ,
    formTitle() {
      return this.editedIndex === -1
        ? "Cadastrar Composição"
        : "Editar Composição";
    },



  },

  watch: {

    getSumValueInputs: {
      handler() {
        this.editedItem.total = this.getSumValueInputs;
        this.editedItem.tax = this.getSumTax;
        this.editedItem.total_with_tax = this.getSumTotal;
      },
    },
    // tax: {
    //   handler() {
    //     this.editedItem.tax = this.tax;
    //     this.calcValorTotal();
    //   },
    // },
    options: {
      handler() {
        this.initialize();
      },
    },
    deep: true,
    dialog(val) {
      val || this.close();
      if (this.editedIndex === -1) {
        this.editedItem.composition_group_id = this.getCompositionGroupId;
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapMutations("Composition", [
      "setInputs",
      "setCompositions",
      "setSumValueInputs",
    ]),

    codeGroup(){
        if( this.editedItem.composition_group !== undefined ){
            return  this.editedItem.composition_group.code + '-'
        }
            return ''
    },

    initialize() {
      this.loading_data_table = true;

      const { page, itemsPerPage } = this.options;
      let compositionGroupId = this.getCompositionGroupId;
      service
        .getAll(itemsPerPage || 10, page, compositionGroupId, this.search)
        .then((response) => {

          this.desserts = response.data.data;
          this.totalStagesPaginate = response.data.total;
          this.numberOfPagesPaginate = response.data.last_page;
          this.currentPagePaginate = response.data.current_page;
          this.loading_data_table = false;
        });

      service.getGroupComposition().then((response) => {
        this.items_group_composition = response.data;
      });
    },
    searchItems() {
      this.options = {};
      this.initialize();
    },
    nameWithId(item) {
      return item.code + ' - ' + item.name;
    },
    newItem() {  //nova composicao
      this.copyCompositionActive = false
      this.disable_inputs = false;
      this.dialog = true;
    },
    copyItem(item) { // copy composicao
      this.copyCompositionActive = true
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, item);
      delete this.editedItem['id']
      this.tax = this.editedItem.tax;
      this.setInputs(Object.assign([], this.editedItem.input));
      this.setCompositions(this.editedItem.composition);
      this.disable_inputs = false;
      this.dialog = true;
    },
    editItem(item) { // editar composicao
    this.copyCompositionActive = false
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.tax = this.editedItem.tax;
      this.setInputs(Object.assign([], this.editedItem.input));
      this.setCompositions(this.editedItem.composition);
      this.disable_inputs = false;
      this.dialog = true;
    },

    showItem(item) { //mostrar composicao
    this.copyCompositionActive = false
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.tax = this.editedItem.tax;
      this.setInputs(this.editedItem.input);
      this.setCompositions(this.editedItem.composition);
      this.disable_inputs = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let editedIndex = this.editedIndex;
      service.destroy(this.editedItem).then(() => {
        this.desserts.splice(editedIndex, 1);
        this.initialize();
        this.$toast.success("Deletado com sucesso.");
      });

      this.closeDelete();
    },

    close() {
      this.clearInputsAndCompositionsTables();
      this.dialog = false;
      this.$nextTick(() => {
        this.setSumValueInputs();
        this.tax = "";
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.btnLoadingSave = true;
      this.editedItem.input = this.getInputs;
      this.editedItem.composition = this.getCompositions;

      if (this.editedIndex > -1) {
        //update
        let indice = this.editedIndex;

        service
          .update(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            Object.assign(this.desserts[indice], response.data);
            this.clearInputsAndCompositionsTables();
            this.close();
            this.initialize();
            this.$toast.success("Salvo com sucesso.");
          })
          .catch(() => {
            this.btnLoadingSave = false;
          });
      } else {
        //store



        service
          .store(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            this.desserts.push(response.data);
            this.clearInputsAndCompositionsTables();
            this.close();
            this.$toast.success("Salvo com sucesso.");
            if (this.desserts.length == 1) { this.initialize() }
          })
          .catch(() => {
            this.btnLoadingSave = false;
          });
      }
    },
    clearInputsAndCompositionsTables() {
      this.setInputs([]);
      this.setCompositions([]);
    },
    calcValorTotal() {
       let tax = this.parseNumber(this.tax);
       this.editedItem.total_with_tax = this.parseNumber(this.editedItem.total) + tax;
    },
    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
    formatMoney(number) {
      number = this.parseNumber(number)
      return number.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formatMoneyPrecision3(number) {
      number = this.parseNumber(number)
      return number.toLocaleString('pt-br', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
    },
  },
};
</script>
