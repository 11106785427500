<template>
<div>
    <v-card outlined>
        <v-data-table :items-per-page="-1" dense :headers="headers" :items="getCompositions" sort-by="calories" class="elevation-0" :disable-pagination="true" hide-default-footer>

            <template v-slot:item.unity="{ item }">
                {{ item.unity }}
            </template>
            <template v-slot:item.name="{ item }">
                <div style="width:550px">{{item.composition_group.code + '-' + item.code+' - '+ item.name }}</div>
            </template>
            <template v-slot:item.total="{ item }">
                {{ formatMoneyPrecision3(item.total) }}
            </template>
            <template v-slot:item.calc_total="{ item }">
                {{ formatMoneyPrecision3(item.pivot.quantity * item.total) }}
            </template>

            <template v-slot:item.pivot.quantity="{ item }">
                <v-edit-dialog :return-value.sync="item.pivot.quantity" large
                                            cancel-text="CANCELAR" save-text="SALVAR"
                                            @save="saveQuantityComposition(item)">
                                            <div  style="width:100px;float:right" class="editable">
                                                {{ formatMoneyPrecision3(item.pivot.quantity) }}
                                            </div>
                                            <template v-slot:input>
                                                <vuetify-money v-model="item.pivot.quantity" :clearable="true"
                                                    valueWhenIsEmpty="0" :options="{
                                                        locale: 'pt-BR',
                                                        length: 11,
                                                        precision: 3,
                                                    }" />
                                            </template>
                                        </v-edit-dialog>

            </template>

            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Composições</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn class="mx-2" :disabled="disabled" small fab dark @click="showDialogFindInput" color="primary">
                        <v-icon dark> mdi-plus </v-icon>
                    </v-btn>

                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <BarTitle title="Excluir" @close="dialogDelete = false"></BarTitle>
                            <v-card-text  class="text-center text-h6">Tem certeza de que deseja excluir este item?</v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="mr-2" min-width="150" color="secondary" large text @click="closeDelete">fechar</v-btn>
                                <v-btn color="error" min-width="150" large @click="deleteItemConfirm">SIM</v-btn>

                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon color="error" :disabled="disabled"  @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data> Nenhum registro foi encontrado. </template>
            <template v-slot:foot>
                <tr>
                    <td class="tax back_tax" colspan='3'></td>
                    <td class="tax text-right back_tax">ENCARGOS:</td>
                    <td class="tax text-right back_tax">{{ formatMoneyPrecision3(calcTax) }}</td>
                    <td class="tax back_tax"></td>
                </tr>
                <tr>
                    <td class="tax back_total" colspan='3'></td>
                    <td class="tax text-right back_total">TOTAL:</td>
                    <td class="tax text-right back_total">{{ formatMoneyPrecision3(calcTotal)}}</td>
                    <td class="tax back_total"></td>
                </tr>
            </template>
        </v-data-table>
    </v-card>

    <!-- dialog de pesquisa de composicao -->
    <v-dialog v-model="dialog" max-width="900px">
        <v-card>
            <!-- <v-card-title>
                <span class="text-h5">Localizar Composição</span>
            </v-card-title> -->
            <BarTitle title="Localizar Composição" @close="dialog=false"></BarTitle>
            <v-card-text>
                <v-container>
                    <v-row>
                         <v-col cols="">
                            <v-text-field outlined v-model="searchInput" @keyup.enter="findComposition()" @click:append="findComposition()" append-icon="mdi-magnify" label="Localizar">
                            </v-text-field>
                        </v-col>

                    </v-row>

                    <v-data-table height='355' dense :loading="loadindTableFindInputs" :headers="headersFindInputs" :items="findInputsData" :items-per-page="10" class="elevation-1" @click:row="setInputSelected" :footer-props="{
                                itemsPerPageAllText: 'todos',
                                itemsPerPageText: 'linhas por pagina'
                            }">
                        <template v-slot:item.code="{ item }">
                            {{ item.composition_group.code + '-' + item.code }}
                        </template>

                        <template v-slot:item.total_with_tax="{ item }">
                            {{ formatMoney(item.total_with_tax) }}
                        </template>

                        <template v-slot:no-data>
                            Nenhum registro foi encontrado.
                        </template>
                    </v-data-table>
                    <v-expand-transition>
                        <v-card class="mt-6" v-show="showInputSelected">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="7">
                                        <v-text-field outlined hide-details  type="text" label="Insumo" :readonly="true" v-model="findInputSelected.name"></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field outlined hide-details class="ml-2" type="number" label="Quantidade" :error-messages="errorQuantity" v-model="findInputSelected.pivot.quantity"></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-btn color="primary" x-large  class="ml-2" @click="applyInputSelected()" >Aplicar</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-expand-transition>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    mapGetters,
    mapMutations,
    mapState
} from "vuex";
import BarTitle from "@/pages/Components/BarTitle"
import service from "../service";
export default {
    props: {
        disabled: Boolean
    },
    components:{
        BarTitle
    },
    data: () => ({
        errorQuantity: [],
        showInputSelected: false,
        findInputSelected: {
            pivot: {
                quantity: ""
            }
        },
        loadindTableFindInputs: false,
        findInputsData: [],
        searchInput: "",
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: "DESCRIÇÃO",
                value: "name",
                sortable: false
            },
            {
                text: "UNID",
                align:'center',
                value: "unity",
                sortable: false
            },
            {
                text: "COEF.",
                align:'right',
                value: "pivot.quantity",
                sortable: false
            },
            {
                text: "P UNIT(R$)",
                align:'right',
                value: "total",
                sortable: false
            },
            {
                text: "SUB-TOTAL(R$)",
                align:'right',
                value: "calc_total",
                sortable: false
            },
            {
                text: "",
                value: "actions",
                align:'right',
                sortable: false
            },
        ],
        headersFindInputs: [{
                text: "CÓDIGO",
                align: "start",
                sortable: false,
                value: "code",
            },
            {
                text: "NOME",
                value: "name"
            },
            {
                text: "UNIDADE",
                value: "unity"
            },
            {
                text: "VALOR TOTAL",
                value: "total_with_tax"
            },

        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            code: "",
            name: "",
            unity: "",
            total: "",
        },
        defaultItem: {
            code: "",
            name: "",
            unity: "",
            total: "",
        },
    }),

    computed: {
        ...mapGetters("Composition", ["getCompositions"]),
        ...mapState("Composition", {
            compositions: (state) => state.compositions,
        }),
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        },
        calcTax() {
            const totaltax = this.getCompositions.reduce((acc, input) => {
                return acc + parseFloat(input.pivot.quantity * (input.tax ))
            }, 0)
            return totaltax
        },
        calcTotal() {
            const total = this.getCompositions.reduce((acc, input) => {
                return acc + parseFloat(input.pivot.quantity * input.total_with_tax)
            }, 0)
            return total
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    methods: {
        ...mapMutations("Composition", ["deleteComposition", "addComposition",'saveQuantityComposition']),

                deleteItem(item) {
            this.editedIndex = this.compositions.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.deleteComposition(this.editedIndex);
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem);
            } else {
                this.desserts.push(this.editedItem);
            }
            this.close();
        },
        findComposition() {
            this.loadindTableFindInputs = true;
            service.getFindCompostions(this.searchInput).then((response) => {
                this.loadindTableFindInputs = false;
                this.findInputsData = response.data;
            });
        },
        setInputSelected(input) {
            input = Object.assign({}, input);
            this.showInputSelected = true
            input.pivot = {
                quantity: ''
            }
            this.findInputSelected = input
        },
        applyInputSelected() {

            if (this.findInputSelected.pivot.quantity <= 0) {
                this.errorQuantity = ['Obrigatório']
                this.$toast.error("Quantidade é um campo obrigatório");
            } else {
                this.errorQuantity = []

                this.addComposition(this.findInputSelected)
                this.close();
                this.showInputSelected = false
            }

        },
        showDialogFindInput() {
            this.searchInput = ''
            this.showInputSelected = false
            this.errorQuantity = []
            this.dialog = true
            this.loadindTableFindInputs = true;
            service.getFindCompostions('').then((response) => {
                this.loadindTableFindInputs = false;
                this.findInputsData = response.data;
            });
        },
        formatMoney(number) {
            number = this.parseNumber(number)

            return number.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },
        formatMoneyPrecision3(number){
            number = this.parseNumber(number)

            return number.toLocaleString('pt-br', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
            })
        },
        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        }

    },
};
</script>

<style  scoped>
    .editable {
        color: #1976d2;
        font-weight: bold
    }

    .tax {
        box-sizing: border-box;
        padding-right: 16px;
        padding-left: 16px;
        height: 30px;
        font-weight: bold;
    }

    .back_tax {
        background: lightgray;
    }

    .back_total {
        background: #1976d2;
        color: white;
    }
</style>
